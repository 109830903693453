<template>
    <div>
      <big-title>
         Re<span>d</span>feridos.
      </big-title>

      <vx-card title="Si la Red crece, ¡tú también!">
        <div class="vx-row">
          <div class="vx-col xxl:w-3/4 w-full">
            <p>
              En RedGirasol queremos que nuestra comunidad sea cada vez más grande y para lograrlo queremos que tú crezcas junto con nosotros.
              <br><br>
              Gracias a nuestro programa de <span class="bold">Re(d)feridos</span>, tú y las personas que invites a la Red podrán obtener un bono
              del <span class="bold">{{taker_bonus_prcg}}%</span> sobre todas las inversiones que realicen durante un mes, así que invita a tus amigos, conocidos, familiares o a quien tu quieras a participar en la
              transición energética de México. ¡Para todos brilla el sol!
            </p>
          </div>
        </div>
      </vx-card>

      <vx-card title="¿Cómo obtengo los beneficios?" class="mt-5">
        <div class="vx-row">
          <div class="vx-col xxl:w-3/4 w-full">
            <p>
              Para que puedas obtener los beneficios del programa de <span class="bold">Re(d)feridos</span> deberás compartir la liga
              que se presenta a continuación y asegurarte que tus invitados se registren con ella, ya que de lo contrario las bonificaciones
              no se aplicarán en tus inversiones.
            </p>
          </div>
        </div>

        <div class="vx-row mt-5">
          <div class="vx-col w-full">
            <h6>¡Copia la liga y compártela!</h6>
          </div>
        </div>
        <div class="vx-row mt-5" v-if="!editing && hasCode">
          <div class="vx-col w-full">
            <div class="vx-col w-full flex">
              <div class="redferido-section-container">
                <span class="sm:mx-8 mx-4 bold">redgirasol.com/r/{{currentCode}}</span>
              </div>
              <vs-button class="ml-2 pt-3 pb-2 px-3" color="primary"  @click.stop="copyToClipboard">
                <feather-icon icon="FileTextIcon" svg-classes="h-9 w-9" />
              </vs-button>
              <vs-button type="border" class="ml-6 px-4" @click="setEditMode">Personalizar</vs-button>
            </div>
          </div>

          <div class="vx-col w-full mt-4">
            <p class="italic">Podrás personalizarla en cualquier momento si así lo deseas.</p>
          </div>
        </div>

        <div class="vx-row mt-5 mb-3" v-if="editing">
          <div class="vx-col lg:w-1/2 w-full">
            <vs-alert v-if="errorMssg" icon-pack="feather" icon="icon-alert-triangle" class="mt-4 mb-2" color="danger">
              <span class="font-regular">{{errorMssg}}</span>
            </vs-alert>
            <div class="flex flex-wrap items-center data-list-btn-container">

              <vx-input-group class="mt-2">
                <template slot="prepend">
                  <div class="prepend-text bg-primary" >
                    <span class="bold">redgirasol.com/r/</span>
                  </div>
                </template>
                <vs-input
                  placeholder="Ingresa el código"
                  v-model="newCode"
                  maxlength="15"
                  type="text"
                />
              </vx-input-group>

              <vs-button type="border" class="ml-4 mt-2" @click="saveNewCode">Guardar</vs-button>
              <vs-button v-if="hasCode" type="border" class="ml-4 mt-2" @click="cancelEditMode">Cancelar</vs-button>
            </div>
          </div>
        </div>
      </vx-card>

      <div class="vx-row mb-8 mt-3">
        <div class="vx-col lg:w-1/4 md:w-1/2 sm:w-1/2 w-1/2 mt-5">
          <vx-card>
            <h1>{{referedCount}}</h1>
            <p class="mt-1 text-lg">No. de Redferidos</p>
          </vx-card>
        </div>
        <div class="vx-col lg:w-1/4 md:w-1/2 sm:w-1/2 w-1/2 mt-5">
          <vx-card>
            <h1>${{generatedBonusFormat}}</h1>
            <p class="mt-1 text-lg">Bonos Generados</p>
          </vx-card>
        </div>
        <div class="vx-col lg:w-1/4 md:w-1/2 sm:w-1/2 w-1/2 mt-5">
          <vx-card>
            <h1>${{usedBonusFormat}}</h1>
            <p class="mt-1 text-lg">Bonos Utilizados</p>
          </vx-card>
        </div>
        <div class="vx-col lg:w-1/4 md:w-1/2 sm:w-1/2 w-1/2 mt-5">
          <vx-card card-background="primary">
            <h1 class="text-white">${{availableBonusFormat}}</h1>
            <p class="text-white mt-1 text-lg">Bonos Disponibles</p>
          </vx-card>
        </div>
      </div>

      <vx-card class="mt-5" title="¿A cuántos has re(d)ferido?" v-if="hasCode">
        <div class="vx-row">
          <div class="vx-col xxl:w-3/4 w-full">
            <p>
              Consulta la lista de las personas que has re(d)feridos y que juntos están limpiando al planeta. <br><br>
              Recuerda que todos los bonos tienen {{ bonusValidity }} días de vigencia. Para utilizar tus bonos sólo selecciona los proyectos en
              los que quieras invertir y se tomarán los bonos que tienes disponibles.
            </p>
          </div>
        </div>

        <div class="vx-row mt-6" v-if="refered.length > 0">
          <div class="vx-col w-full">
            <h6>Bonus generados por mis Redferidos</h6>
            <vs-table ref="table" stripe noDataText="No hay datos disponibles" :data="refered" class="mt-2">
              <template slot="thead">
                <vs-th class="bold">Nombre</vs-th>
                <vs-th class="bold">Fecha registro</vs-th>
                <vs-th class="bold">Monto invertido</vs-th>
                <vs-th class="bold">Bonos generados</vs-th>
                <vs-th class="bold">Bonos utilizados</vs-th>
              </template>
              <template slot-scope="{data}">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td>{{ tr.name }}</vs-td>
                  <vs-td>{{ tr.registered }}</vs-td>
                  <vs-td>{{ explicitMoneyFormat(tr.invested) }}</vs-td>
                  <vs-td>{{ explicitMoneyFormat(tr.bonus_generated) }}</vs-td>
                  <vs-td>{{ explicitMoneyFormat(tr.bonus_used) }}</vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </div>

        <div class="vx-row mt-6" v-if="myBonuses.length > 0">
          <div class="vx-col w-full">
            <h6>Bonus generados por mis inversiones</h6>
            <vs-table ref="table" stripe noDataText="No hay datos disponibles" :data="myBonuses" class="mt-2">
              <template slot="thead">
                <vs-th class="bold">Fecha de creación</vs-th>
                <vs-th class="bold">Bono generado</vs-th>
                <vs-th class="bold">Vigencia</vs-th>
                <vs-th class="bold">Bono utilizado</vs-th>
              </template>
              <template slot-scope="{data}">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td>{{ tr.created_at_f }}</vs-td>
                  <vs-td>{{ explicitMoneyFormat(tr.amount_f) }}</vs-td>
                  <vs-td>{{ tr.valid }} {{ tr.bonus_expired ? ' (Vencido)' : '' }}</vs-td>
                  <vs-td>{{ explicitMoneyFormat(tr.used_amount_f) }}</vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </div>

        <div class="vx-row mt-6">
          <div class="vx-col w-full">
            <p class="mt-1 bold">Condiciones del Programa Redferidos:</p>
            <ul class="mt-2 ml-6" style="list-style-type: square;">
              <li>Tus referidos pueden generar bonos durante los primeros {{ bonusCreationDays }} días a partir de su registro.</li>
              <li>Los bonos tienen una vigencia de {{ bonusValidity }} días.</li>
              <li>Los bonos sólo se pueden usar para invertir en proyectos, no se suman al saldo del Inversionista.</li>
              <li>Este programa no tiene una vigencia definida, pero RedGirasol se reserva el derecho de terminarlo sin previo aviso.
                Los bonos continuan con su vigencia posterior a la terminación del programa.</li>
              <li>No se permite que una misma persona tenga más de una cuenta y aproveche el programa más de una vez.
                En caso de que RedGirasol detecte esto, se ajustará del saldo según corresponda.</li>
            </ul>
          </div>
        </div>
      </vx-card>
   </div>
</template>

<script>
import formatHelper from "@mixins/formatHelper";
export default {
  mixins: [formatHelper],
  data(){
    return {
      isMounted: false,
      hasCode: false,
      currentCode : null,
      newCode : null,
      editing: false,
      referedCount: 0,
      generatedBonus: 0,
      usedBonus: 0,
      availableBonus: 0,
      bonusValidity: 0,
      bonusCreationDays: 0,
      errorMssg: null,
      refered: [],
      myBonuses: [],
      taker_bonus_prcg: 0,
      maker_bonus_prcg: 0,
    }
  },
  async beforeMount(){
    this.isMounted = false
    await this.getRedferidosInfo();
    this.isMounted = true
  },
  computed: {
    generatedBonusFormat (){
      return this.generatedBonus;
      //return this.explicitMoneyFormat(this.generatedBonus, 2);
    },
    usedBonusFormat (){
      return this.usedBonus;
      //return this.explicitMoneyFormat(this.usedBonus, 2);
    },
    availableBonusFormat (){
      return this.availableBonus;
      //return this.explicitMoneyFormat(this.availableBonus, 2);
    },
  },
  methods: {
    async getRedferidosInfo(){
      this.startLoading()
      try {
        const res = await axios.get('api/inversionista/redferidos');
        if(res.data === "empty"){
          // no existe un codigo aun
          this.hasCode = false;
          this.editing = true;
        }
        else {
          this.currentCode = res.data.referal.uri;
          this.hasCode = true;
          this.availableBonus = res.data.bonus.available;
          this.generatedBonus = res.data.bonus.generated;
          this.usedBonus = res.data.bonus.used
          this.referedCount = res.data.refered.length;
          this.bonusValidity = res.data.bonus.validity;
          this.bonusCreationDays = res.data.bonus.creation_days;
          this.refered = res.data.refered;
          this.myBonuses = res.data.my_bonus;

          this.maker_bonus_prcg = res.data.maker_bonus_percentage * 100;
          this.taker_bonus_prcg = res.data.taker_bonus_percentage * 100;
        }
      }
      catch (e){
        this.hasCode = false;
        this.errorMssg = "Ha ocurrido un error";
      }
      this.stopLoading()
    },
    setEditMode(){
      this.newCode = this.currentCode;
      this.editing = true;
    },
    async saveNewCode(){
      this.editing = false;
      this.errorMssg = null;
      if(this.currentCode === this.newCode){
        return;
      }

      try {
        const res = await axios.post('/api/inversionista/redferidos', {
          id: this.UserId,
          code: this.newCode
        });

        const saved = res.data.saved;
        if(saved === "no"){
          this.errorMssg = "El código que intentas ingresar ya esta siendo utilizado, prueba con otro por favor.";
          this.editing = true;
        }
        else if(saved === "ok"){
          this.editing = false;
          this.hasCode = true;
          this.currentCode = this.newCode;
          await this.getRedferidosInfo();
          this.successNotif("Tu código se ha actualizado correctamente");
        }
      }
      catch (e) {
        this.errorMssg = "Ha ocurrido un error, por favor intenta más tarde.";
        this.editing = true;
      }
    },
    copyToClipboard (){
      const el = document.createElement('textarea');
      el.value = `www.redgirasol.com/r/${this.currentCode}`;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.successNotif("Liga copiada al portapapeles");
    },
    cancelEditMode(){
      this.editing = false;
    }
  }
}
</script>